import * as React from 'react';
import Map from '../components/map';
import Seo from '../components/seo';
import Layout from '../layouts/layout';
import { useLocation } from '@reach/router';

export default function ExploreMap() {
  const { hash } = useLocation();
  let initialLocation = {
    zoom: 14.5,
    lng: -104.861505,
    lat: 39.375489
  }
  const isLocationHash = /^#{1}\d*\.{0,1}\d+\/{1}-?\d*\.{0,1}\d+\/{1}-?\d*\.{0,1}\d+$/.test(hash);
  if (isLocationHash){
    let zoom, lng, lat;
    [ zoom, lat, lng ] = hash.slice(1).split('/');
    initialLocation = { zoom: zoom, lng: lng, lat: lat };
  }
  return (
    <Layout>
      <Seo title="Explore Map" />
      <Map startLocation={initialLocation} />
    </Layout>
  );
}
