import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import outdoor from '../assets/images/map-toggle-images/outdoors.webp';
import topo from '../assets/images/map-toggle-images/topo.webp';
import darkGrey from '../assets/images/map-toggle-images/dark_grey.webp';
import lightGrey from '../assets/images/map-toggle-images/light_grey.webp';
import terrain from '../assets/images/map-toggle-images/terrain.webp';
import satellite from '../assets/images/map-toggle-images/satellite.webp';

const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 80px)',
  zIndex: 1,
};

export default function Map({startLocation}) {
  const mapStyles = [
    {
      style: outdoor,
      name: 'Outdoor',
      url: 'https://maps.accuterra.com/v1/styles/accuterra-outdoors/style.json',
    },
    {
      style: topo,
      name: 'Topo',
      url: 'https://maps.accuterra.com/v1/styles/accuterra-topo/style.json',
    },
    {
      style: darkGrey,
      name: 'Dark Grey',
      url: 'https://maps.accuterra.com/v1/styles/dark-grey/style.json',
    },
    {
      style: lightGrey,
      name: 'Light Grey',
      url: 'https://maps.accuterra.com/v1/styles/light-grey/style.json',
    },
    {
      style: terrain,
      name: 'Terrain',
      url: 'https://maps.accuterra.com/v1/styles/accuterra-terrain/style.json',
    },
    {
      style: satellite,
      name: 'Satellite',
      url: 'https://maps.accuterra.com/v1/styles/labels/style.json',
    },
  ];

  const [activeMap, setActiveMap] = useState(mapStyles[0]);
  const [mapFilterOpen, setMapFilterOpen] = useState(false);
  
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(startLocation?.lng || -105.5217);
  const [lat] = useState(startLocation?.lat || 40.3772);
  const [zoom] = useState(startLocation?.zoom || 11);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      accessToken: process.env.GATSBY_ENV_MAPBOX_TOKEN,
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
      customAttribution:
        '<a href="https://accuterra.com/" target="_blank">© AccuTerra</a>',
    });

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.current.setStyle(
      `${activeMap.url}?key=${process.env.GATSBY_ACCUTERRA_API_KEY}`
    );
    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      })
    );

    return () => map.current.remove();
  }, []);

  const setMapStyles = (style) => {
    map.current.setStyle(
      `${style.url}?key=${process.env.GATSBY_ACCUTERRA_API_KEY}`
    );
    setActiveMap(style);
    setMapFilterOpen(false);
  };

  return (
    <div className="relative ">
      <div className="flex flex-col-reverse justify-start items-center  absolute  bottom-4 left-4 w-16 z-10">
        {!mapFilterOpen && (
          <div
            onClick={() => setMapFilterOpen(true)}
            className="relative overflow-hidden  m-1   shadow-md hover:shadow-lg rounded-lg border-white border-2 h-16 w-16 text-white cursor-pointer"
          >
            <div className="map__thumb__text absolute top-0 left-0  font-semibold h-full w-full text-white text-center text-xs flex items-center justify-center z-20">
              {activeMap.name}
            </div>
            <img
              src={activeMap.style}
              alt={activeMap.name}
              className=" absolute top-0 left-0 z-10 h-full object-cover"
            />
          </div>
        )}

        {mapFilterOpen &&
          mapStyles.map((style) => (
            <div
              key={style.name}
              className="relative overflow-hidden  m-1  shadow-md hover:shadow-lg rounded-lg border-white border-2 h-16 w-16 cursor-pointer"
              onClick={() => setMapStyles(style)}
            >
              <div className="map__thumb__text no--select absolute top-0 left-0  font-semibold h-full w-full text-white text-center text-xs flex items-center justify-center z-20">
                {style.name}
              </div>
              <img
                src={style.style}
                alt={style.name}
                className=" absolute top-0 left-0 z-10 h-full object-cover"
              />
            </div>
          ))}
      </div>

      <div ref={mapContainer} style={mapContainerStyle} />
    </div>
  );
};
